import axios from '@/libs/axios'
import endpoints from '@/http/endpoints'

export default {
  async getListByFilter(planId) {
    const result = await axios.get(endpoints.membershipSubscribers.Controller.getListByFilter(planId))
    return result
  },
  async changeToYearly(planId) {
    const result = await axios.get(endpoints.membershipSubscribers.Controller.changeToYearly(planId))
    return result
  },
  async getPageableQuery(pageable, filters) {
    const result = await axios.post(endpoints.membershipSubscribers.Controller.getByPageableQuery(pageable), filters)
    return result
  },
  async findById(id) {
    const result = await axios.post(endpoints.membershipSubscribers.Controller.findById(id))
    return result
  },
  async create(data) {
    const result = await axios.post(endpoints.membershipSubscribers.Controller.create(), data)
    return result
  },
  async update(data) {
    const result = await axios.put(endpoints.membershipSubscribers.Controller.update(), data)
    return result
  },
  async delete(id) {
    const result = await axios.delete(endpoints.membershipSubscribers.Controller.delete(id))
    return result
  },
  async cancel(planId) {
    const result = await axios.get(endpoints.membershipSubscribers.Controller.cancel(planId))
    return result
  },
  async cancelCompanyPlan(companyId, planId) {
    const result = await axios.get(endpoints.membershipSubscribers.Controller.cancelCompanyPlan(companyId, planId))
    return result
  },

  // #region add
  async addMemberShipSubscribers(data) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.membershipManagement.Controller.addSubscriber(), data)
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  // #endregion

  // #region delete
  async deleteMemberShipSubscribers(data) {
    const promise = new Promise((resolve, reject) => {
      axios.delete(endpoints.membershipManagement.Controller.deleteSubscriber(), data)
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)

    return promise
  },
  // #endregion

  // #region edit
  async editMemberDhipSubscribers(data) {
    const promise = new Promise((resolve, reject) => {
      axios.put(endpoints.membershipManagement.Controller.editSubscriber(), data)
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)

    return promise
  },
  // #endregion

  // #region get
  async getSubscriptionForCompany(membershiptypeid, companyId) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.membershipManagement.Controller.getSubscribersForCompany(membershiptypeid, companyId))
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async getSubscriptionsByTypeId(membershiptypeid) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.membershipManagement.Controller.getSubscriberByTypeId(membershiptypeid))
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async getSubscriptionForECommerce(membershiptypeid, ecommerceId) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.membershipManagement.Controller.getSubscribersForECommerce(membershiptypeid, ecommerceId))
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async getMemberShipSubscribers(data) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.membershipManagement.Controller.getSubscriber(data))
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  // #endregion
}
